
import common from '@/mixin/common.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'r-join-detail',
  mixins: [common],
  data() {
    return {
      productBgImage: require('@/assets/join/bg.jpg'),
      contentBgImage: require('@/assets/join/content_bg.png'),
    }
  },
  watch: {
    jobInfo(val) {
      this.resetBread();
    }
  },
  computed: {
    ...mapGetters('join', ['addressList', 'jobInfo']),
    jobDetail: function() {
      return [{
        typeName: this.$t('join.responsibility'),
        desInfo: this.jobInfo.duty
      },{
        typeName: this.$t('join.jobrequire'),
        desInfo: this.jobInfo.requirements
      },{
        typeName: this.$t('join.send'),
        desInfo: this.$t('join.sendDes'),
      }]
    }
  },

  created() {
    this.getSelectList();
  },
  mounted() {
    this.getJobDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions('join', ['getSelectList', 'getJobDetail']),
    resetBread() {
      const txt = this.jobInfo['name' + this.zhPageKey];
      if (txt) {
        this.$refs.breadcrumb.changeLastBread(txt);
      }
    },
    changePageLanguage() {
      this.resetBread();
    },
    buildLocation(ids, zhPageKey) {
      const cityNames = [];
      const countryNames = [];
      for (const id of ids) {
        const city = this.addressList.find(u => u.id === parseInt(id));
        if (!city) {
          continue;
        }
        cityNames.push(city['name' + zhPageKey]);
        const countryName = city.country['name' + zhPageKey];
        if (countryNames.indexOf(countryName) < 0) {
          countryNames.push(countryName);
        }
      }
      return `${cityNames.join(' / ')}<div class="comma">,</div>${countryNames.join(' / ')}`;
    },
  },
}